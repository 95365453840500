/**
 * 账户管理相关API
 */

import request from '@/utils/request'

/**
 * 查询账户交易流水表列表，用于分页查询
 * @param params
 */
export function getDistributionPage(params) {
  return request({
    url: `/seller/trade/distribution/page`,
    method: 'post',
    params
  })
}

/** 获取结算明细 */
export function getBillData(params) {
  return request({
    url: 'seller/trade/distribution/bill',
    method: 'get',
    params
  })
}

/** 导出结算明细 */
export function exportBillData(params) {
  return request({
    url: 'seller/trade/distribution/exportBill',
    method: 'get',
    params
  })
}

/**
 * 查询账户交易流水详情
 * @param params
 */
export function getDistributionInfo(params) {
  return request({
    url: `/seller/trade/distribution/info`,
    method: 'get',
    params
  })
}
/**
 * 查询退款明细
 * @param data
 */
export function getRefundDetail(params) {
  return request({
    url: '/seller/trade/distribution/getRefundDetail',
    method: 'get',
    params
  })
}

/**
 * 活动编辑提交
 * @param data
 */
export function activeGoodsActivityEdit(data) {
  return request({
    url: '/active/goodsActivity/edit',
    method: 'post',
    headers: { "Content-Type": "application/json" },
    data
  })
}

/**
 * 查询提现记录表列表，用于分页查询
 * @param data
 */
export function getWithdrawalList(params) {
  return request({
    url: '/seller/shops/withdrawal/page',
    method: 'post',
    params
  })
}

/**
 * 获取店铺提现金额
 * @param data
 */
export function getShopsInfo() {
  return request({
    url: '/seller/shops/shops',
    method: 'get'
  })
}

/**
 * 退款明细导出
 */
export function exportRefundData(params) {
  return request({
    url: 'seller/trade/distribution/export',
    method: 'get',
    params
  })
}
